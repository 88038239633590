<template>
  <div class="main" :style="mainHeight">
    <div class="tab_header">
        <ul class="div">
          <li class="top_li">
            <img :src="balanceAccount" class="balanceAccount">
            <div class="center">
              <p><font class="blue_font">{{user.userInfor.remainingDays}}</font><font style="font-size:0.9rem">天</font></p>
              <p>预计可使用天数</p>
            </div>
            <router-link to="/pay" class="to_pay" tag="a"  v-if="paramInfo.wechatPayFlag === 'Y'">充值</router-link>
          </li>
          <li>
            <span class="line">
              当前账户余额为：{{user.userInfor.balance}}元
            </span>
            <span class="line">
              <span class="font_line">余额预警天数：{{paramInfo.balanceAlarmDays}}天</span>
              <font class="font_line" style="font-size:0.7rem;">注:当前可用天数为{{paramInfo.balanceAlarmDays}}天时系统将自动提醒</font>
            </span>
            <span class="line small_span">
              预计可使用天数=当前账户余额/近30天平均扣费额
            </span>
            <!-- <span class="line" style="border:none;padding-bottom: 0;">
              近30天扣费详情
            </span> -->
            <div class="charts" v-show="showCanvas">
              <font class="title_font">近30天扣费详情</font>
              <div id="my_charts" ref="my_charts" :style="myChartsSize"></div>
            </div>
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
  import { userDetail } from '@/api/home'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { userDB } from '@/db'
  import balanceAccount from '@/assets/home/xiaofei.png'
  import echarts from 'echarts'
  import api from '@/api/index'
  const { fetchData } = api

  const vipId = userDB.get('vipId')
  export default {
    data() {
      return {
        showCanvas: false,
        userName: '',
        days: 6,
        balance: 0,
        showSelet: false,
        mainHeight: `height:${window.innerHeight || document.documentElement.clientHeight}px;overflow:hidden`,
        userRemainder: '',
        companyName: '',
        pageCount: 0,
        loading: false,
        finished: true,
        myChartsSize: `width:${document.body.clientWidth}px;height:15rem`,
        lists: [],
        showActive: -1,
        currentList: [],
        paramInfo: {},
        balanceAccount,
        option: {
          legend: {
            data: []
          },
          toolbox: {
          },
          xAxis: [
            {
              type: 'category',
              data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              // interval: 50,
              name: '用量（度）',
              axisLabel: {
                margin: 2,
                formatter: function(value, index) {
                  if (value >= 10000 && value < 10000000) {
                    value = value / 10000 + '万'
                  } else if (value >= 10000000) {
                    value = value / 10000000 + '千万'
                  }
                  return value
                }
              }
            }
          ],
          grid: {
            left: 35
          },
          series: [
            {
              name: '充值金额', // 充值金额
              type: 'bar',
              smooth: true,
              itemStyle: { normal: { color: '#00A39B' }},
              data: [111, 31, 44, 43, 5, 111, 31, 44, 43, 5, 111, 31, 44, 43, 5, 111, 31, 44, 43, 5, 111, 31, 44, 43, 5, 111, 31, 44, 43, 5]
            }
          ]
        }
      }
    },
    computed: {
      ...mapState(['user'])
    },
    filters: {
      clearTime(val) {
        if ((!!val) && (val.indexOf('T'))) {
          return val.split('T')[0]
        }
        return val
      }
    },
    created() {
      this.myChartsSize = `width:${document.body.offsetWidth}px;height:15rem`
      this.getPramaInfo()
    },
    mounted() {
      const _self = this
      _self.showCanvas = true
      const currentdate = new Date()
      const previousDate = new Date(currentdate.getTime() - (30 * 24 * 3600 * 1000))
      const endDate = `${currentdate.getFullYear()}-${this.formatDays(currentdate.getMonth() + 1)}-${this.formatDays(currentdate.getDate())}`
      const beginDate = `${previousDate.getFullYear()}-${this.formatDays(previousDate.getMonth() + 1)}-${this.formatDays(previousDate.getDate())}`
      fetchData(
        {},
        `/running/fees/data/vip/${vipId}/dayData/${beginDate}/${endDate}`,
        'get'
      ).then(res => {
        const amountList = []
        const dataList = []
        res.list.map(item => {
          amountList.push(item.amount)
          dataList.push(item.dataDate)
        })
        this.option.series[0].data = amountList
        this.option.xAxis[0].data = dataList
        const myChart = echarts.init(document.getElementById('my_charts'))
        myChart.setOption(this.option)
      })
    },
    methods: {
      formatDays(val) {
        if (parseInt(val) < 10) {
          return `0${val}`
        } else {
          return val
        }
      },
      // 获取用户参数
      getPramaInfo() {
        fetchData(
          {},
          `/config/paramInfo`,
          'get'
        ).then(res => {
          this.paramInfo = res
        }).catch(err => {
          console.log(err)
        })
      },
      /*
        加法函数
        @parmas arg1(被加数字) arg2(被加数字)
        @return 返回计算结果
      */
      accAdd(arg1, arg2) {
        var r1, r2, m
        try {
          r1 = arg1.toString().split('.')[1].length
        } catch (e) {
          r1 = 0
        } try {
          r2 = arg2.toString().split('.')[1].length
        } catch (e) {
          r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        return (arg1 * m + arg2 * m) / m
      }
    }
  }
</script>

<style scoped lang="scss">
.main {
    background: #fff;
  }
  .font_line {
    display: inline-block;
    width: 100%;
  }
  .blue_font {
    color:#00A49C;
    padding-right: 10px ;
  }
  .btnBox {
    height: auto;
    overflow: hidden;
    float: left;
    margin: 20px auto;
    height: 3rem;
    width: 100%;
    a {
      display: inline-block;
      width: 33%;
      margin-left: 10%;
      text-align: center;
      background: #ea4a45;
      color: #fff;
      border: 1px solid #ea4a45;
      border-radius: 40px;
    }
  }
  .tab_header {
    width:100%;
    float: left;
    font-size: 1rem;
    height: 100%;
    overflow: auto;
    background: #fff;
    ul {
      height: auto;
      min-height: 3rem;
      line-height: 2.4rem;
      color: #333;
      background: #fff;
      width: 100%;
      float: left;
      padding:0;
      font-size: 1.1rem;
      li {
        background: #fff;
        height: auto;
        min-height: 4rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        overflow: hidden;
        .title_font {
          margin-left:5%;
          font-size: 0.8rem;
          line-height: 1.5rem;
          margin-top: 0.7rem;
          float: left;
        }
        p{
          padding-left: 1rem;
        }
        .charts {
          width: 100%;
          height: 16rem;
          overflow: hidden;
          float: left;
        }
        #my_charts {
          float: left;
          margin: 0 20px;
          margin-bottom: 2rem;
          // margin-left: calc((100%- 300px)/2);
        }
        .to_pay {
          display: inline-block;
          float: right;
          width: 5rem;
          height: 2rem;
          border-radius:0.3rem;
          line-height: 2rem;
          margin-top: 1rem;
          margin-right: 1rem;
          background: #FFBE3E;
          border: 1px solid #FFBE3E;
          text-align: center;
          color: #fff;
        }
        .center {
          float: left;
          width: 40%;
          height: 4rem;
          p {
            line-height: 1.9rem;
            padding: 0;
            margin: 0;
            font-size: 0.8rem;
          }
          p:first-child {
            margin-top: 0.4rem;
            font-size: 1.4rem;
          }
        }
        .line {
          display: inline-block;
          width: calc(100% - 60px);
          height: auto;
          min-height: 1.5rem;
          line-height: 1.5rem;
          margin:0 30px;
          padding: 0.7rem 0;
          font-size: 0.9rem;
          border-bottom:1px solid #e6e6e6;
          color: #333;
          font {
            color: #999;
          }
        }
        .small_span {
          font-size: 0.8rem;
        }
        .balanceAccount {
          float: left;
          height: 3rem;
          margin: 0.5rem 0.8rem;
        }
      }
      li:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
      .top_li {
        width: calc(100% - 40px);
        margin: 5% 20px;
        box-shadow: 0px 0px 20px #cdcdcd;
        padding: 30px 0;
        border-radius: 0.3rem;
      }
    }
  }
</style>
